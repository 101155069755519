.userDropdownButton {
  @apply flex flex-row items-center px-2 rounded bg-indigo-600 text-white ml-2 mr-2;
}

.discordIcon {
  @apply bg-white h-4 w-4 my-1.5;
  mask: url("~assets/svgs/discord.svg") no-repeat center;
}

.dropdown {
  @apply relative inline-block;
  cursor: pointer;
}

.dropdownContent {
  @apply hidden absolute bg-black shadow z-10 top-full right-0 w-full;
  min-width: 200px;
}

.dropdownItem {
  @apply block px-4 py-2 text-white hover:bg-indigo-600 w-full;
}

.dropdown:focus-within .dropdownContent {
  @apply block;
}
