.searchBarBorder {
  height: 2px;
}

.locked{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

}

.padtop{
  padding-top: 2.6em;
}
